import React from 'react'
import { withTranslation } from 'react-i18next'

import I18n from '../components/i18n'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Gallery from '../components/gallery'

const ProductsPage = ({ t }) => {
  return (
    <Layout>
      <SEO title={t('products.title')} keywords={[`agriculture`, `cultivate`, `japan`, `vietnam`, `pepper`]} />
			<div className="page">
				<div className="container grid-lg">
					<h1 className="page-title" dangerouslySetInnerHTML={{ __html: t('products.title') }}></h1>

          <h2 className="page-subtitle">1. {t('products.product1.title')}</h2>
          <div className="columns">
            <div className="column col-5 col-lg-12 section-content">
              <p>{t('products.original')}: {t('products.product1.original')}</p>
              <p>{t('products.size')}: {t('products.product1.size')}</p>
              <p>{t('products.ingredients')}: <a href="/analysis_chicken_fertilizer_powder.pdf" target="_blank">File PDF</a></p>
            </div>
            <div className="column col-7 col-lg-12">
              <Gallery baseClass="import" sliderItems={[
                { id: 1, url: require('../images/product1_1.jpg') },
                { id: 2, url: require('../images/product1_2.jpg') },
                { id: 3, url: require('../images/product1_3.jpg') },
                { id: 4, url: require('../images/product1_4.jpg') },
              ]}/>
            </div>
          </div>

          <h2 className="page-subtitle">2. {t('products.product2.title')}</h2>
          <div className="columns">
            <div className="column col-5 col-lg-12 section-content">
              <p>{t('products.original')}: {t('products.product2.original')}</p>
              <p>{t('products.color')}: {t('products.product2.color')}</p>
              <p>{t('products.ingredients')}: <a href="/analysis_chicken_fertilizer_powder.pdf" target="_blank">File PDF</a></p>
            </div>
            <div className="column col-7 col-lg-12">
              <Gallery baseClass="import" sliderItems={[
                { id: 1, url: require('../images/product2_1.jpg') },
                { id: 2, url: require('../images/product2_2.jpg') },
                { id: 3, url: require('../images/product2_3.jpg') },
                { id: 4, url: require('../images/product2_4.jpg') },
                { id: 5, url: require('../images/product2_5.jpg') },
              ]}/>
            </div>
          </div>

          <h2 className="page-subtitle">3. {t('products.product3.title')}</h2>
          <div className="columns">
            <div className="column col-5 col-lg-12 section-content">
              <p>{t('products.original')}: {t('products.product3.original')}</p>
              <p>{t('products.color')}: {t('products.product3.color')}</p>
              <p>{t('products.ingredients')}: <a href="/analysis_of_ash.pdf" target="_blank">File PDF</a></p>
            </div>
            <div className="column col-7 col-lg-12">
              <Gallery baseClass="import" sliderItems={[
                { id: 1, url: require('../images/product3_1.jpg') },
                { id: 2, url: require('../images/product3_2.jpg') },
                { id: 3, url: require('../images/product3_3.jpg') },
                { id: 4, url: require('../images/product3_4.jpg') },
              ]}/>
            </div>
          </div>
				</div>
			</div>
    </Layout>
  )
}

export default withTranslation()(ProductsPage)
